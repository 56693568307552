.App {
  text-align: center;
  /* font-family: 'Josefin Sans', sans-serif; */
  font-family: 'Inter', 'Montserrat', sans-serif;
}

.navbar-button {
  margin-right: 20px;
}

.nav-link {
  color: #777 !important;
  font-size: small !important;
}

/* Hover state */
.nav-link:hover {
  color: #000 !important; /* Custom hover color */
}

.navbar-brand {
  color: #555 !important;
  font-weight: 600 !important;
  font-family: Josefin Sans, sans-serif !important;
  font-size: x-large !important;
}

/* General styles for both arrows */
.default-nav {
  margin: 0.5em !important; /* Add margin */
}

/* In your CSS file */
.dark-gray-nav-link {
  color: #333 !important; /* Using !important to override default styles */
}

.container_about {
  margin-top: 50px;
}

/* Gallery styles */

.gallery-container {
  width: 100%;
  margin: auto;
  position: absolute;
}

.slider-container {
  height: 1500px;  /* Adjust height as needed */
  position: absolute;
  overflow: hidden;
}

.controls {
  text-align: center;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.info {
  text-align: center;
  margin-top: 10px;
}
